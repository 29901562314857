import { useState, useEffect } from 'react';
import { collection, getDocs, query, orderBy, limit, where } from 'firebase/firestore';
import {db} from  '../firebase'
import type { NewsArticle } from '../types/news';

export const useNews = (categoryId?: string, newsLimit: number = 5) => {
  const [news, setNews] = useState<NewsArticle[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  

  useEffect(() => {
    const fetchNews = async () => {
      try {
        let newsQuery = query(
          collection(db, 'articles'),
          orderBy('created_at'),
          limit(newsLimit)
        );

        if (categoryId) {
          newsQuery = query(
            collection(db, 'articles'),
            where('category.id', '==', categoryId),
            orderBy('created_at'),
            limit(newsLimit)
          );
        }

        const snapshot = await getDocs(newsQuery);
        const newsData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })) as NewsArticle[];

        setNews(newsData);
      } catch (error) {
        setError(error instanceof Error ? error.message : 'Error fetching news');
      } finally {
        setLoading(false);
      }
    };

    fetchNews();
  }, [db, categoryId, newsLimit]);

  return { news, loading, error };
};