import React, { useEffect, useState } from 'react';
import NewsCard from '../components/NewsCard';
import ScrollableImages from '../components/ScrollableImages';
import { db } from '../firebase';
import { collection, getDocs, query, orderBy } from 'firebase/firestore';
import { NewsArticle } from '../types/news';
import AdvertisementSection from '../components/AdvertisementSection';
import MarqueeNews from '../components/MarqueeNews';
import { Link } from 'react-router-dom';
import VideoModal from '../components/Videoplayer/VideoModal';
import VideoCard from '../components/Videoplayer/VideoCard';

interface Category {
  id: string;
  name: string;
}

interface Video {
  id: string;
  title: string;
  link: string;
}

const Home: React.FC = () => {
  const [news, setNews] = useState<NewsArticle[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [showMore, setShowMore] = useState<string | null>(null);
  const [loading, setLoading] = useState(true); // Loading state
  const [videos, setVideos] = useState<Video[]>([]);
  const [modalVideo, setModalVideo] = useState<{ title: string; link: string } | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch categories
        const categorySnapshot = await getDocs(collection(db, 'categories'));
        const categoryData = categorySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        })) as Category[];
        setCategories(categoryData);

        // Fetch news articles
        const newsQuery = query(collection(db, 'articles'), orderBy('created_at', 'desc'));
        const newsSnapshot = await getDocs(newsQuery);
        const newsData = newsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        })) as NewsArticle[];
        setNews(newsData);

        // Fetch videos
        const videosRef = query(collection(db, 'videos'), orderBy('createdAt', 'desc'));
        const snapshot = await getDocs(videosRef);
        const videoData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        })) as Video[];
        setVideos(videoData);

      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures this runs once when the component mounts

  const handleShowMore = (categoryId: string) => {
    setShowMore(prev => (prev === categoryId ? null : categoryId));
  };

  if (loading) {
    return (
      <div className="min-h-screen flex justify-center items-center bg-gray-50">
        <div className="loader">
          <svg
            className="animate-spin h-12 w-12 text-red-500"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8v8H4z"
            ></path>
          </svg>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 min-w-screen">
      <div className="mx-auto">
        {/* Marquee Section */}
        <div className="bg-blue-200 text-blue-800 py-2">
          <MarqueeNews />
        </div>

        {/* Main Content */}
        <div className="lg:flex ">
          {/* Left Column - News Articles */}
          <div className="lg:w-3/4">
            {/* Latest News Section */}
            <div className="bg-white p-5 rounded-md shadow-md">
              <h2 className="text-2xl font-semibold">Latest News</h2>
              <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-4">
                {news.slice(0, 8).map((article, index) => (
                  <NewsCard key={index} data={article} />
                ))}
              </div>
              <Link
                        key={categories[0].id}
                        to={`/category/${null}/${"Latest News"}`}
                        className="block px-2 py-1 text-sm hover:bg-gray-600 rounded  text-center bg-blue-500"
                      >
                        Show More
                      </Link>
            </div>

            {/* News by Category Section */}
            {categories.map(category => {
              const filteredArticles = news.filter(
                article => article.category.id === category.id
              );
              const isExpanded = showMore === category.id;

              if (filteredArticles.length > 0) {
                return (
                  <div key={category.id} className="bg-white rounded-md shadow-md m-2 h-75">
                    
                    <h3 className="text-xl font-semibold p-4">{category.name}</h3>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 p-5">
                      {filteredArticles
                        .slice(0, isExpanded ? filteredArticles.length : 8)
                        .map((article, index) => (
                          <NewsCard key={index} data={article} />
                        ))}
                    </div>
                    {filteredArticles.length > 0 && (
                      <Link
                        key={category.id}
                        to={`/category/${category.id}/${category.name}`}
                        className=" block px-2 py-1 text-sm hover:bg-gray-600 rounded text-center bg-blue-500 text-white"
                      >
                        Show More
                      </Link>
                    )}
                  </div>
                );
              }
              return null;
            })}
          </div>

          {/* Right Column - Images and Ads */}
          <div className="lg:mt-0 lg:w-1/3 sm:w-full ">
            <div className="bg-white rounded-md shadow-md">
              <h3 className="text-xl font-semibold p-4">Short News</h3>
              <ScrollableImages />
            </div>
            <br />
            {/* Featured Video Section */}
            <div className="bg-white p-1 rounded-md shadow-md">
              <h3 className="text-xl font-semibold p-4">Youtube Video</h3>
              {videos.length > 0 && (
                <div className="p-4">
                  <VideoCard
                    key={videos[0].id}
                    title={videos[0].title}
                    link={videos[0].link}
                    onPlay={(title, link) => setModalVideo({ title, link })}
                  />
                  {modalVideo && (
                    <VideoModal
                      isOpen={!!modalVideo}
                      title={modalVideo.title}
                      videoUrl={modalVideo.link}
                      onClose={() => setModalVideo(null)}
                    />
                  )}
                  {/* Show More Videos Button */}
                  {videos.length > 1 && (
                    <button
                      className="mt-4 text-white hover:bg-gray-800 p-3 rounded bg-red-600"
                    >
                      <a href="/YTvideos">Show More Videos</a>
                      
                    </button>
                  )}
                </div>
              )}
            </div>
              <br />
            <div className="bg-white p-6 rounded-md shadow-md">
              <AdvertisementSection />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
