import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './pages/HomePage';
import NewsDetailPage from './pages/NewsDetailPage';
import CategoryNewsScreen from './pages/CategoryNewsScreen';
import Footer from './components/footer/Footer';
import WelcomeModal from './components/Welcome/welcomemodal';
import AllCards from './pages/CardsPage';
import Youtubevideopage from './pages/VideoPlayerPage';
// import NewsDetailPage from './pages/NewsDetailPage';


const App: React.FC = () => {
  return (
    <Router>
      <WelcomeModal />
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/news/:id" element={<NewsDetailPage />} />
        <Route path="/category/:id/:name" element={< CategoryNewsScreen/>} />
        <Route path="/allcards" element={<AllCards />} />
        <Route path="/YTvideos" element={<Youtubevideopage/>} />
      </Routes>
      <Footer/>
    </Router>
  );
};

export default App;
