import React from 'react';
import type { NewsArticle } from '../../types/news';

interface NewsHeaderProps {
  news: NewsArticle;
}

const NewsHeader: React.FC<NewsHeaderProps> = ({ news }) => {
  // Convert Firestore Timestamp to JavaScript Date object
  const createdAtDate = news.created_at.toDate();

  return (
    <div className="space-y-4">
      {/* Category and Date Section */}
      <div className="flex items-center space-x-4 text-sm text-gray-600">
        <span className="font-semibold bg-green-800 rounded p-2 text-white">{news.category.name}</span> {/* Category label */}
        <span>•</span>
        <time dateTime={createdAtDate.toISOString()}>
          {createdAtDate.toLocaleDateString('ta-IN', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })}
        </time>
      </div>

      {/* Title Section */}
      <h1 className="text-3xl font-bold text-gray-900">{news.title}</h1>

      {/* Author Section with Image */}
      <div className="flex items-center space-x-4 text-sm">
        {/* Author's Profile Image */}
        <img
          src={news.author.image_url}
          alt={news.author.name}
          className="w-8 h-8 rounded-full object-cover" // Rounded image with size 8
        />
        
        <span className="text-gray-600">Published By : <b>{news.author.name}</b></span>
      </div>
    </div>
  );
};

export default NewsHeader;
