import React from 'react';
import { useNavigate } from 'react-router-dom';
import { NewsArticle } from '../types/news';

interface NewsCardProps {
  data: NewsArticle;
}

const NewsCard: React.FC<NewsCardProps> = ({ data }) => {
  const navigate = useNavigate();

  const handlePress = () => {
    navigate(`/news/${data.id}`); // Navigate to news detail page
    window.scrollTo(0, 0);
  };

  return (
    <div
      onClick={handlePress}
      className="bg-gray-100  shadow-md rounded-lg  p-3 flex flex-col justify-between lg:max-w-xs lg:w-50 h-85 sm:w-full cursor-pointer"
    >
      {/* Image */}
      <div className="sm:w-20 lg:w-full bg-gray-200">
        <img className="w-full h-36 object-cover rounded-md" src={data.image_url} alt="news" />
      </div>

      {/* Title */}
      <h2 className="text-base text-sm font-semibold   line-clamp-3  truncate-0 max-w-full" title={data.title}>
        {data.title}
      </h2>

      {/* Description */}
      <h3 className="text-base text-xs line-clamp-3 truncate-3 p-1 max-w-full tamil-text" title={data.summary}>
        {data.summary}
      </h3>

      {/* Category */}
      <div className="text-sm text-white w-fit p-1 rounded bg-red-600 mt-auto">
        {data.category.name}
      </div>
    </div>
  );
};

export default NewsCard;
