import React from 'react';
import type { NewsArticle } from '../../types/news';
import DOMPurify from 'dompurify';

interface NewsContentProps {
  news: NewsArticle;
}

const NewsContent: React.FC<NewsContentProps> = ({ news }) => {
  return (
    <article className="prose prose-lg max-w-none">
      <img
        src={news.image_url}
        alt={news.title}
        className="w-full object-cover rounded-lg shadow-lg mb-8"
      />
      
      <div  className="text-sm leading-8 font-serif"
  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(news.description) }}
/>
    </article>
  );
};

export default NewsContent;
