import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Menu, X } from 'lucide-react';
import { collection, getDocs, query, orderBy } from 'firebase/firestore';
import { db } from '../firebase';
import SocialIcons from '../components/socialicon';

interface Category {
  id: string;
  name: string;
  parent_id?: string;
  subCategories?: Category[];
}

const Navbar: React.FC = () => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const q = query(collection(db, 'categories'), orderBy('created_at', 'asc'));
        const querySnapshot = await getDocs(q);
        const fetchedCategories = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as Category[];

        const parentCategories = fetchedCategories.filter((category) => !category.parent_id);
        const subCategories = fetchedCategories.filter((category) => category.parent_id);

        const groupedSubCategories = parentCategories.map((parent) => ({
          ...parent,
          subCategories: subCategories.filter((sub) => sub.parent_id === parent.id),
        }));

        setCategories(groupedSubCategories);
      } catch (error) {
        console.error('Error fetching categories:', error);
        setError('Failed to load categories.');
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <nav className="bg-red-700 text-white">
      <div className="max-w-6xl mx-auto px-2 sm:px-6 lg:px-4">
        {/* Top bar with logo and social icons */}
        <div className="flex flex-col items-center py-2 md:flex-row md:justify-between">
        <div className="hidden md:flex md:items-center md:space-x-8">
              <Link to="/" className="hover:text-yellow-400">Home</Link>
              <Link to="/contact" className="hover:text-yellow-400">Contact</Link>
            </div>

          {/* Logo centered on mobile, left on desktop */}
          <div className="flex-shrink-0 mb-4 md:mb-0">
            <Link to="/">
              <img src="https://i.ibb.co/qNB9dsV/logo-png.png" alt="logo" className="h-12" />
            </Link>
          </div>

          {/* Social Icons */}
          <div className="hidden md:block">
            <SocialIcons />
          </div>
        </div>

        {/* Navigation and Categories */}
        <div className="border-t border-red-600">
          <div className="flex items-center justify-between h-9">
            {/* Desktop Navigation */}
          
            {/* Mobile Social Icons and Menu Button */}
            <div className="flex w-full justify-between space-x-7 md:hidden">
              <SocialIcons />
              <button
                onClick={toggleMenu}
                className="inline-flex items-center justify-center p-2 rounded-md text-white hover:text-yellow-400 focus:outline-none"
              >
                {isMenuOpen ? (
                  <X className="h-6 w-6" />
                ) : (
                  <Menu className="h-6 w-6" />
                )}
              </button>
            </div>
          </div>

          {/* Desktop Categories */}
          <div className="hidden md:flex md:justify-center md:space-x-4 pb-4">
            {categories.map((category) => (
              <div key={category.id} className="relative group">
                <span className={`w--full font-semibold  hover:bg-gray-600 rounded p-2 hover:text-yellow-400 cursor-pointer'}`}>
                  <Link
                    to={`/category/${category.id}/${category.name}`}
                    className={` p-1 rounded font-semibold hover:bg-gray-600 ${category.subCategories?.length ? 'cursor-not-allowed pointer-events-none' : 'hover:text-yellow-400'}`}
                  >
                    {category.name}
                  </Link>
                </span>
                {category.subCategories?.length! > 0 && (
                  <div className="absolute hidden group-hover:block bg-gray-700 p-2 rounded shadow-lg z-50">
                    {category.subCategories!.map((subCategory) => (
                      <Link
                        key={subCategory.id}
                        to={`/category/${subCategory.id}/${subCategory.name}`}
                        className="block px-2 py-1 text-sm hover:bg-gray-600 rounded"
                        onClick={closeMenu}
                      >
                        {subCategory.name}
                      </Link>
                    ))}
                  </div>
                )}
              </div>
            ))}
             <Link
                      
                        to={`/allcards`}
                        className="block px-2 py-1 text-sm hover:bg-gray-600 rounded font-semibold hover:text-yellow-400 cursor-pointer"
                       
                      >
                        கார்ட்ஸ்
                      </Link>
             <Link
                      
                        to={`/YTvideos`}
                        className="block px-2 py-1 text-sm hover:bg-gray-600 rounded font-semibold hover:text-yellow-400 cursor-pointer"
                       
                      >
                        Youtube Videos
                      </Link>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      <div className={`${isMenuOpen ? 'block' : 'hidden'} md:hidden`}>
        <div className="px-2 pt-2 pb-3 space-y-1">
          <Link
            to="/"
            className="block px-3 py-2 rounded-md text-base font-medium hover:bg-red-800 hover:text-yellow-400"
            onClick={closeMenu}
          >
            Home
          </Link>
          <Link
            to="/contact"
            className="block px-3 py-2 rounded-md text-base font-medium hover:bg-red-800 hover:text-yellow-400"
            onClick={closeMenu}
          >
            Contact
          </Link>
          
          {/* Mobile Categories */}
          {categories.map((category) => (
            <div key={category.id} className="space-y-1">
              <div className="px-3 py-2 font-medium">
                {category.name}
              </div>
              {category.subCategories?.length! > 0 && (
                <div className="pl-6 space-y-1">
                  {category.subCategories!.map((subCategory) => (
                    <Link
                      key={subCategory.id}
                      to={`/category/${subCategory.id}/${subCategory.name}`}
                      className="block px-3 py-2 rounded-md text-sm font-medium hover:bg-red-800 hover:text-yellow-400"
                      onClick={closeMenu}
                    >
                      {subCategory.name}
                    </Link>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;