// Import Firebase
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

// Firebase configuration (replace with your Firebase config)
const firebaseConfig = {
    apiKey: "AIzaSyCQK9lg7DLYhWyc2tWXMopMLwFc3O6Zk5U",
    authDomain: "namdhina.firebaseapp.com",
    databaseURL: "https://namdhina-default-rtdb.firebaseio.com",
    projectId: "namdhina",
    storageBucket: "namdhina.appspot.com",
    messagingSenderId: "235960579541",
    appId: "1:235960579541:web:5d7b8ecaed44545a28795c",
    measurementId: "G-K8XF7J14EC"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);

export { db, storage };
