import React, { useState, useEffect } from 'react';
import { PartyPopper, X } from 'lucide-react';
import Firework from '../Welcome/fireworks';
import Confetti from '../Welcome/confetti';

const WelcomeModal: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [hasBeenShown, setHasBeenShown] = useState(false);

  useEffect(() => {
    const shown = localStorage.getItem('welcomeShown');
    if (!shown) {
      const timer = setTimeout(() => {
        setIsVisible(true);
        setHasBeenShown(true);
        localStorage.setItem('welcomeShown', 'true');
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, []);

  if (!isVisible) return null;

  const fireworks = [
    { color: '#FF0000', delay: 0, position: { x: 10, y: 10 } },
    { color: '#00FF00', delay: 0.2, position: { x: 90, y: 30 } },
    { color: '#0000FF', delay: 0.4, position: { x: 10, y: 70 } },
    { color: '#FFD700', delay: 0.6, position: { x: 70, y: 10 } },
  ];

  const confetti = Array.from({ length: 50 }, (_, i) => ({
    color: ['#FF0000', '#00FF00', '#0000FF', '#FFD700', '#FF69B4'][Math.floor(Math.random() * 5)],
    delay: Math.random() * 0.5,
  }));

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black/50 backdrop-blur-sm">
      <div className="relative w-full max-w-md mx-4 bg-white rounded-lg shadow-xl overflow-hidden">
        {/* Fireworks */}
        <div className="absolute inset-0 overflow-hidden pointer-events-none">
          {fireworks.map((fw, i) => (
            <Firework key={i} {...fw} />
          ))}
          {confetti.map((conf, i) => (
            <Confetti key={i} {...conf} />
          ))}
        </div>

        {/* Content */}
        <div className="relative p-6 text-center">
          <button
            onClick={() => setIsVisible(false)}
            className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 transition-colors"
          >
            <X className="w-6 h-6" />
          </button>

          <div className="mb-4 flex justify-center">
            <PartyPopper className="w-16 h-16 text-yellow-500 animate-bounce" />
          </div>

          <h2 className="text-2xl font-bold mb-2 text-gray-800 animate-fade-in">
          நம் தினமதி நியூஸில் உங்களை வரவேற்கின்றோம்
          </h2>
          
          <p className="text-gray-600 mb-4 animate-slide-up">
          திருவண்ணாமலை மாவட்டத்தின் முன்னணி செய்தி வெளியீட்டு ஊடகம். எங்களை ஆதரித்து பயன்பெறுங்கள். நன்றி.
          </p>

          <button
            onClick={() => setIsVisible(false)}
            className="bg-red-700 text-white px-6 py-2 rounded-full font-semibold
                     hover:bg-red-800 transform hover:scale-105 transition-all
                     animate-pulse"
          >
             தொடங்கலாம்
          </button>
        </div>
      </div>
    </div>
  );
};

export default WelcomeModal;