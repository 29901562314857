import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import VideoCard from '../components/Videoplayer/VideoCard';
import VideoModal from '../components/Videoplayer/VideoModal';

interface Video {
  id: string;
  title: string;
  link: string;
}

const Youtubevideopage: React.FC = () => {
  const [videos, setVideos] = useState<Video[]>([]);
  const [modalVideo, setModalVideo] = useState<{ title: string; link: string } | null>(null);
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const videosRef = query(collection(db, 'videos'), orderBy('createdAt', 'desc'));
        const snapshot = await getDocs(videosRef);
        const videoData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        })) as Video[];
        setVideos(videoData);
      } catch (error) {
        console.error('Error fetching videos:', error);
      } finally {
        setLoading(false); // Set loading to false once data is fetched
      }
    };

    fetchVideos();
  }, []);

  return (
    <div className={`p-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 `}>
      {/* Loading Indicator */}
      {loading && (
        <div className="absolute inset-0 bg-gray-200 opacity-50 flex justify-center items-center z-10  blur-sm">
          <svg
            className="animate-spin h-12 w-12 text-red-500"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8v8H4z"
            ></path>
          </svg>
        </div>
      )}

      {/* Video Cards */}
      {videos.map(video => (
        <VideoCard
          key={video.id}
          title={video.title}
          link={video.link}
          onPlay={(title, link) => setModalVideo({ title, link })}
        />
      ))}

      {/* Video Modal */}
      {modalVideo && (
        <VideoModal
          isOpen={!!modalVideo}
          title={modalVideo.title}
          videoUrl={modalVideo.link}
          onClose={() => setModalVideo(null)}
        />
      )}
    </div>
  );
};

export default Youtubevideopage;
