import React, { useEffect, useRef } from 'react';

interface VideoModalProps {
  isOpen: boolean;
  title: string;
  videoUrl: string;
  onClose: () => void;
}

const extractVideoId = (url: string): string | null => {
  const regExp = /(?:youtube\.com\/.*v=|youtu\.be\/)([^&#?/]+)/;
  const match = url.match(regExp);
  return match ? match[1] : null;
};

const VideoModal: React.FC<VideoModalProps> = ({ isOpen, title, videoUrl, onClose }) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    if (isOpen && iframeRef.current) {
      const onIframeLoaded = () => {
        const iframe = iframeRef.current;
        if (iframe) {
          const iframeWindow = iframe.contentWindow;
          if (iframeWindow) {
            iframeWindow.postMessage(
              '{"event":"command","func":"playVideo","args":""}',
              '*'
            );
          }
        }
      };

      if (iframeRef.current) {
        iframeRef.current.onload = onIframeLoaded;
      }
    }
  }, [isOpen]);

  const handleVideoEnd = () => {
    console.log('video closed')
    onClose();
  };

  if (!isOpen) return null;

  const videoId = extractVideoId(videoUrl);
  if (!videoId) {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
        <div className="bg-white p-4 rounded-lg text-center">
          <p className="text-red-600">Invalid video URL</p>
          <button onClick={onClose} className="mt-4 px-4 py-2 bg-gray-800 text-white rounded-md">
            Close
          </button>
        </div>
      </div>
    );
  }

  const embedUrl = `https://www.youtube.com/embed/${videoId}?autoplay=1`;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white w-full max-w-3xl rounded-lg overflow-hidden relative">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-black bg-gray-200 rounded-full p-2 hover:bg-gray-300"
        >
          ✖
        </button>
        <div className="p-4 bg-gray-800 text-white text-center">
          <h2 className="text-lg font-bold">{title}</h2>
        </div>
        <div className="aspect-video">
          <iframe
            ref={iframeRef}
            className="w-full h-full"
            src={embedUrl}
            title={title}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            onEnded={handleVideoEnd} // This detects when the video ends
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default VideoModal;
