import React from 'react';
import { Youtube, Instagram, Smartphone, ArrowDownToLine , Facebook} from 'lucide-react';


const SocialIcons: React.FC = () => {
  return (
    <div className="flex items-center space-x-4">
      <a
        href="https://www.youtube.com/channel/UC1CuKJPM6751fljPzoy869Q"
        target="_blank"
        rel="noopener noreferrer"
        className="hover:text-yellow-400 transition-colors"
      >
        <Youtube className="h-6 w-6" />
      </a>
      <a
        href="https://www.instagram.com/tiruvannamalai_namdinamathi?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
        target="_blank"
        rel="noopener noreferrer"
        className="hover:text-yellow-400 transition-colors"
      >
        <Instagram className="h-6 w-6" />
      </a>
      <a
        href="https://www.facebook.com/NamDinamathi/"
        target="_blank"
        rel="noopener noreferrer"
        className="hover:text-yellow-400 transition-colors"
      >
        
        <Facebook className="h-6 w-6" />
      </a>
      <a
        href="#"
        className="hover:text-yellow-400 transition-colors flex bg-green-600 rounded p-1 sm:w-30"
      >
        <Smartphone className="h-6 w-6 " /> 
        <a href="./app-release.apk" download target="_blank" rel="noopener noreferrer" className='text-sm'>
                Download App 
                </a>
        <ArrowDownToLine className="h-4 w-4  m-1"  />

      </a>
    </div>
  );
};

export default SocialIcons;