import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import type { NewsArticle, Tag } from '../../types/news';

interface RelatedNewsProps {
  currentArticle: NewsArticle; // The article that the user is currently viewing
  news: NewsArticle[];         // All the articles available for related news
  tags: Tag[];                 // All the tags available in the system
}

const RelatedNews: React.FC<RelatedNewsProps> = ({ currentArticle, news, tags }) => {
  const navigate = useNavigate();

  // Get the tag names or tag IDs of the current article
  const currentArticleTagIds = currentArticle.tag_ids;

  // Find articles that share any tag ID with the current article
  const relatedArticles = news.filter((article) => {
    // Check if any tag ID from the current article exists in the other article's tag_ids
    return (
      article.id !== currentArticle.id && // Exclude the current article
      article.tag_ids.some((tagId) => currentArticleTagIds.includes(tagId)) // Match tag IDs
    );
  });

  if (relatedArticles.length === 0) return null;

  const handleNavigate = (articleId: string) => {
    navigate(`/news/${articleId}`); // Navigate to the selected article
    window.scrollTo(0, 0); // Scroll to the top of the page
  };

  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold text-gray-900">தொடர்புடைய செய்திகள்</h2>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {relatedArticles.map((article) => (
          <div
            key={article.id}
            className="group block cursor-pointer"
            onClick={() => handleNavigate(article.id)}  // Navigate and scroll to the top
          >
            <div className="relative h-48 mb-4">
              <img
                src={article.image_url}
                alt={article.title}
                className="w-full h-full object-cover rounded-lg"
              />
              <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-10 transition-opacity rounded-lg" />
            </div>

            <h3 className="font-semibold text-gray-900 group-hover:text-red-600 transition-colors">
              {article.title}
            </h3>

            {/* Optional: Display tags related to the article */}
            <div className="mt-2 text-sm text-gray-500">
              {article.tag_ids.map((tagId) => {
                const tag = tags.find((t) => t.id === tagId); // Find the tag by ID
                return tag ? <span key={tag.id} className="mr-2">{tag.name}</span> : null;
              })}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RelatedNews;
