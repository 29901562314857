import React from 'react';

interface BubbleProps {
  size: number;
  duration: number;
  delay: number;
  position: number;
}

const Bubble: React.FC<BubbleProps> = ({ size, duration, delay, position }) => {
  return (
    <div
      className="absolute bottom-0 rounded-full bg-white/10"
      style={{
        width: `${size}px`,
        height: `${size}px`,
        left: `${position}%`,
        animation: `rise ${duration}s ${delay}s infinite ease-in`,
      }}
    >
      <style>
        {`
          @keyframes rise {
            0% {
              transform: translateY(0);
              opacity: 1;
            }
            100% {
              transform: translateY(-100vh);
              opacity: 0;
            }
          }
        `}
      </style>
    </div>
  );
};

export default Bubble;
