import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'; // Adjust as needed for your environment
import { fetchArticlesByCategory } from '../hooks/fetcharticlebycate';
import NewsCard from '../components/NewsCard'; // Adjust path if necessary
import { NewsArticle } from '../types/news'; // Adjust path
import ScrollableImages from '../components/ScrollableImages';
import AdvertisementSection from '../components/AdvertisementSection';

const CategoryNewsScreen: React.FC = () => {
    const { id, name } = useParams<{ id: string; name: string }>();
  const [articles, setArticles] = useState<NewsArticle[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Set loading to true at the beginning
  
      console.log(`Fetching articles for category ID: ${id}`);
      const fetchedArticles = await fetchArticlesByCategory(id);
      setArticles(fetchedArticles);
      console.log(fetchedArticles);
      console.log(fetchedArticles);
  
      setLoading(false); // Set loading to false after fetching
    };
  
    fetchData();
  }, [id]);
  

  if (loading)
    return (
      <div className="min-h-screen flex justify-center items-center bg-gray-50">
        <div className="loader">
          <svg
            className="animate-spin h-12 w-12 text-red-500"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8v8H4z"
            ></path>
          </svg>
        </div>
      </div>
    );

  if (!articles.length)
    return (
      <div className="flex justify-center items-center h-screen">
        <p className="text-gray-500 text-xl font-semibold">No articles found.</p>
      </div>
    );

  return (
    <div className="min-h-screen bg-gray-50 min-w-screen">
    <div className="mx-auto">
      {/* Marquee Section */}
      <div className="bg-blue-200 text-blue-800 py-2">
       
      </div>

      {/* Main Content */}
      <div className="lg:flex ">
        {/* Left Column - News Articles */}
        <div className="lg:w-3/4">
          {/* Latest News Section */}
          <div className="bg-white p-5 rounded-md shadow-md">
            <h2 className="text-2xl font-semibold">{name}</h2>
            <div className="grid grid-cols-2 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-4">
              {articles.map((article, index) => (
                <NewsCard key={index} data={article} />
              ))}
            </div>
           
          </div>

          {/* News by Category Section */}
         
        </div>

        {/* Right Column - Images and Ads */}
        <div className="lg:mt-0 w-1/3">
          <div className="bg-white rounded-md shadow-md">
            <h3 className="text-xl font-semibold p-4">Featured Cards</h3>
            <ScrollableImages />
          </div>
          <div className="bg-white p-6 rounded-md shadow-md">
            <AdvertisementSection/>
            
          </div>
        </div>
      </div>
    </div>
  </div>
  );
};

export default CategoryNewsScreen;
