import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebase';
import { NewsArticle , Tag} from '../types/news';



export const fetchTagsByIds = async (tagIds: string[]): Promise<Tag[]> => {
  try {
    const tagsQuery = query(
      collection(db, 'tags'),
      where('id', 'in', tagIds)
    );

    const snapshot = await getDocs(tagsQuery);
    const tagsData = snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    })) as Tag[];

    return tagsData;
  } catch (error) {
    console.error('Error fetching tags: ', error);
    throw new Error('Error fetching tags');
  }
};
export const fetchRelatedArticlesByTags = async (tagIds: string[]): Promise<NewsArticle[]> => {
  try {
    const articlesQuery = query(
      collection(db, 'articles'),
      where('tag_ids', 'array-contains-any', tagIds)
    );

    const snapshot = await getDocs(articlesQuery);
    const articlesData = snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    })) as NewsArticle[];

    return articlesData;
  } catch (error) {
    console.error('Error fetching related articles: ', error);
    throw new Error('Error fetching related articles');
  }
};
