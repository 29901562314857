import { getDoc, doc, getDocs, collection } from 'firebase/firestore';
import { db } from '../firebase'; // Ensure this is properly imported
import React from 'react';
import { Advertisement } from '../types/news';

const AdvertisementSection: React.FC = () => {
  const [ads, setAds] = React.useState<Advertisement | null>(null);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState<string | null>(null);

  React.useEffect(() => {
    const fetchAds = async () => {
      try {
        // Correct reference to the 'ads' document inside the 'app' subcollection under 'settings'
        const appDocRef = doc(db, 'settings', 'app'); // Path to the 'ads' document

        // Fetch the 'ads' document
        const docSnap = await getDoc(appDocRef);

        if (docSnap.exists()) {
          // Document exists, set the ads data
          setAds(docSnap.data()['ads'] as Advertisement);
          console.log(docSnap.data())
        } else {
          setError('No advertisements found.');
        }
      } catch (error) {
        console.error('Error fetching ads:', error);
        setError('Failed to load advertisements.');
      } finally {
        setLoading(false);
      }
    };

    fetchAds();
  }, []);

  if (loading) {
    return (
      <div className="space-y-4">
        <h3 className="text-xl font-bold text-gray-800">விளம்பரங்கள்</h3>
        {[1, 2, 3].map((n) => (
          <div key={n} className="animate-pulse">
            <div className="h-32 bg-gray-200 rounded-lg"></div>
          </div>
        ))}
      </div>
    );
  }

  if (error) {
    return <p className="text-red-500">{error}</p>;
  }

  if (!ads) {
    return <p>No advertisements available at the moment.</p>;
  }

  return (
    <div className="space-y-4">
      <h3 className="text-xl font-bold text-gray-800">விளம்பரங்கள்</h3>
      {/* Banner Ad
      {ads.banner && (
        <div className="space-y-2">
          <h4 className="text-lg font-semibold">{ads.custom_ads[0].title}</h4>
          <a
            href={ads.custom_ads[0].target}
            target="_blank"
            rel="noopener noreferrer"
            className="block"
          >
            <img
              src={ads.custom_ads[0].image} // Ensure that the image field is correct in Firestore
              alt={ads.custom_ads[0].title}
              className="w-full rounded-lg shadow-md hover:shadow-lg transition-shadow"
              loading="lazy"
            />
          </a>
          {ads.custom_ads[0].action_text && (
            <button className="mt-2 px-4 py-2 bg-blue-500 text-white rounded-lg">
              {ads.custom_ads[0].action_text}
            </button>
          )}
        </div>
      )} */}

      {/* Custom Ads */}
      {ads.custom_ads_enabled && ads.custom_ads && (
        <div className="space-y-4">
          {/* <h4 className="text-xl font-bold">Custom Ads</h4> */}
          <div className="space-y-4">
            {ads.custom_ads.map((customAd, index) => (
              <div key={index} className="space-y-2">
                <h5 className="text-lg font-semibold">{customAd.title}</h5>
                <a
                  href={customAd.target}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="block"
                >
                  <img
                    src={customAd.image}
                    alt={customAd.title}
                    className="w-full rounded-lg shadow-md hover:shadow-lg transition-shadow"
                    loading="lazy"
                  />
                </a>
                {customAd.action_text && (
                  <button className="mt-2 px-4 py-2 bg-blue-500 text-white rounded-lg">
                    <a href={customAd.target}>{customAd.action_text}</a>
                    
                  </button>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default AdvertisementSection;
