import React from 'react';

interface VideoCardProps {
  title: string;
  link: string;
  onPlay: (title: string, link: string) => void;
}

const extractVideoId = (url: string): string | null => {
  const regExp = /(?:youtube\.com\/.*v=|youtu\.be\/)([^&#?/]+)/;
  const match = url.match(regExp);
  return match ? match[1] : null;
};

const VideoCard: React.FC<VideoCardProps> = ({ title, link, onPlay }) => {
  const videoId = extractVideoId(link);
  const thumbnailUrl = videoId ? `https://img.youtube.com/vi/${videoId}/hqdefault.jpg` : '';

  const handleClick = () => {
    if (videoId) {
      onPlay(title, link);
    }
  };

  return (
    <div
      className="bg-white shadow-md rounded-md overflow-hidden cursor-pointer"
      onClick={handleClick}
    >
      <img src={thumbnailUrl} alt={title} className="w-full h-48 object-cover" />
      <div className="p-2">
        <h3 className="font-bold text-sm">{title}</h3>
      </div>
    </div>
  );
};

export default VideoCard;
