import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { db } from '../../firebase';
import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import SocialIcons from '../socialicon';
import BubblesContainer from './bubblecontainer';

interface Category {
  id: string;
  name: string;
  parent_id?: string | null;
  subCategories?: Category[];
}

const Footer: React.FC = () => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const q = query(collection(db, 'categories'), orderBy('created_at', 'asc'));
        const querySnapshot = await getDocs(q);
        const fetchedCategories = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as Category[];

        const parentCategories = fetchedCategories.filter((category) => !category.parent_id);
        const subCategories = fetchedCategories.filter((category) => category.parent_id);

        const groupedCategories = parentCategories.map((parent) => ({
          ...parent,
          subCategories: subCategories.filter((sub) => sub.parent_id === parent.id),
        }));

        setCategories(groupedCategories);
      } catch (error) {
        console.error('Error fetching categories:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  function top(){
    window.scrollTo(0, 0);
  };

  const quickLinks = [
    { text: 'Home', href: '/' },
    { text: 'About Us', href: '/about' },
    { text: 'Contact', href: '/contact' },
    { text: 'Privacy Policy', href: '/privacy' },
  ];

  const support = [
    { text: 'FAQ', href: '/faq' },
    { text: 'Terms of Service', href: '/terms' },
    { text: 'Support Center', href: '/support' },
    { text: 'Contact Us', href: '/contact' },
  ];

  return (
    <footer className="bg-red-800 relative">
      <BubblesContainer />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 relative z-10">
        {/* Main Footer Content */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 mb-8">
          {/* Logo and Description */}
          <div className="col-span-1 sm:col-span-2 lg:col-span-1">
            <Link to="/">
              <img
                src="https://i.ibb.co/qNB9dsV/logo-png.png"
                alt="logo"
                className="h-12 mb-4"
              />
            </Link>
            <p className="text-gray-300 mb-4">
              Your trusted source for the latest news and updates. Stay connected with us
              for breaking stories and in-depth coverage.
            </p>
            <SocialIcons />
          </div>

          {/* Quick Links */}
          <div>
            <h3 className="text-white font-bold mb-4">Quick Links</h3>
            <ul>
              {quickLinks.map((link) => (
                <li key={link.text} className="mb-2">
                  <Link
                    to={link.href}
                    className="text-gray-300 hover:text-white transition-colors"
                  >
                    {link.text}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          {/* Categories */}
          <div>
            <h3 className="text-white font-bold mb-4">Categories</h3>
            {loading ? (
              <p className="text-gray-300">Loading categories...</p>
            ) : (
              <ul>
                {categories.map((category) => (
                  <li key={category.id} className="mb-2">
                    {category.subCategories && category.subCategories.length > 0 ? (
                      <details className="group">
                        <summary className="text-gray-300 cursor-pointer hover:text-white transition-colors">
                          {category.name}
                        </summary>
                        <ul className="ml-4 mt-2">
                          {category.subCategories.map((sub) => (
                            <li key={sub.id}>
                              <Link
                                to={`/category/${sub.id}/${sub.name}`}
                                className="text-gray-300 hover:text-white transition-colors"
                                onClick={top}
                              >
                                {sub.name}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </details>
                    ) : (
                      <Link
                        to={`/category/${category.id}/${category.name}`}
                        className="text-gray-300 hover:text-white transition-colors"
                        onClick={top}
                      >
                        {category.name}
                      </Link>
                    )}
                  </li>
                ))}
              </ul>
            )}
          </div>

          {/* Support */}
          <div>
            <h3 className="text-white font-bold mb-4">Support</h3>
            <ul>
              {support.map((link) => (
                <li key={link.text} className="mb-2">
                  <Link
                    to={link.href}
                    className="text-gray-300 hover:text-white transition-colors"
                  >
                    {link.text}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>

        {/* Bottom Bar */}
        <div className="border-t border-red-700 pt-8 mt-8">
          <div className="flex flex-col sm:flex-row justify-between items-center">
            <p className="text-gray-300 text-sm mb-4 sm:mb-0">
              © {new Date().getFullYear()} Zop Technologys. All rights reserved.
            </p>
            <div className="flex space-x-4 text-sm text-gray-300">
              <Link to="/privacy" className="hover:text-white transition-colors">
                Privacy Policy
              </Link>
              <Link to="/terms" className="hover:text-white transition-colors">
                Terms of Service
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
