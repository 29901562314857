import { useState, useEffect } from 'react';
import { doc, getDoc, collection, query, where, limit, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import type { NewsArticle } from '../types/news';

export const useNewsDetail = (newsId: string) => {
  const [news, setNews] = useState<NewsArticle | null>(null);
  const [relatedNews, setRelatedNews] = useState<NewsArticle[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
 

  useEffect(() => {
    const fetchNewsDetail = async () => {
      try {
        const newsDoc = await getDoc(doc(db, 'articles', newsId));
        
        if (!newsDoc.exists()) {
          setError('செய்தி கிடைக்கவில்லை');
          return;
        }

        const newsData = { id: newsDoc.id, ...newsDoc.data() } as NewsArticle;
        setNews(newsData);

        // Fetch related news from the same category
        const relatedQuery = query(
          collection(db, 'articles'),
          where('category.id', '==', newsData.category.id),
          where('id', '!=', newsId),
          limit(4)
        );

        const relatedSnapshot = await getDocs(relatedQuery);
        const relatedData = relatedSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })) as NewsArticle[];

        setRelatedNews(relatedData);
      } catch (error) {
        setError(error instanceof Error ? error.message : 'Error fetching news');
      } finally {
        setLoading(false);
      }
    };

    if (newsId) {
      fetchNewsDetail();
    }
  }, [db, newsId]);

  return { news, relatedNews, loading, error };
};