import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // For navigation
import { db } from '../firebase';
import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import ShimmerPlaceholder from './ShimmerEffect';
import ImageCard from '../components/cards/imagecard';

interface Article {
  id: string;
  title: string;
  imageUrl: string;
  createdAt: any;
}

const ScrollableImages: React.FC = () => {
  const [articles, setArticles] = useState<Article[]>([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const articlesRef = query(collection(db, 'cards'), orderBy('createdAt', 'desc'));
        const snapshot = await getDocs(articlesRef);
        const articlesData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        })) as Article[];
        setArticles(articlesData.slice(0, 1)); // Only fetch the first image
      } catch (error) {
        console.error('Error fetching images:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchImages();
  }, []);

  if (loading) {
    return (
      <div className="flex flex-col items-center gap-4 p-4">
        <ShimmerPlaceholder />
      </div>
    );
  }

  return (
    <div className="bg-white rounded-md shadow-md p-4">
      <div className="flex flex-col items-center">
        {articles.map(article => (
          <ImageCard
            key={article.id}
            imageUrl={article.imageUrl}
            title={article.title}
          />
        ))}
       
      </div>
      <button
          className="mt-4 text-white bg-red-600 hover:bg-gray-800 p-3 rounded"
          onClick={() => navigate('/allcards')}
        >
          மேலும் கார்ட்ஸ்
        </button>
    </div>
  );
};

export default ScrollableImages;
