import React from 'react';
import Bubble from './bubble';

const BubblesContainer: React.FC = () => {
  const bubbles = [
    { size: 30, duration: 8, delay: 0, position: 10 },
    { size: 15, duration: 6, delay: 1, position: 25 },
    { size: 25, duration: 7, delay: 2, position: 45 },
    { size: 40, duration: 9, delay: 0.5, position: 59 },
    { size: 20, duration: 9, delay: 0.5, position: 60 },
    { size: 45, duration: 8, delay: 2.5, position: 55 },
    { size: 35, duration: 8, delay: 1.5, position: 75 },
    { size: 50, duration: 8, delay: 3.5, position: 33 },
    { size: 35, duration: 8, delay: 0.5, position: 95 },
    { size: 39, duration: 6, delay: 2.5, position: 48 },
    { size: 18, duration: 6, delay: 2.5, position: 80 },
  ];

  return (
    <div className="absolute inset-0 overflow-hidden pointer-events-none">
      {bubbles.map((bubble, index) => (
        <Bubble key={index} {...bubble} />
      ))}
    </div>
  );
};

export default BubblesContainer;