import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useNewsDetail } from '../hooks/useNewsDetail';
import { fetchTagsByIds, fetchRelatedArticlesByTags } from '../hooks/fetchRelatedArticlesByTags';  // Import your functions
import NewsHeader from '../components/news/NewsHeader';
import NewsContent from '../components/news/NewsContent';
import RelatedNews from '../components/news/RelatedNews';
import { NewsArticle, Tag } from '../types/news';
import ScrollableImages from '../components/ScrollableImages';
import AdvertisementSection from '../components/AdvertisementSection';

const NewsDetailPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { news, loading, error } = useNewsDetail(id || '');
  const [tags, setTags] = useState<Tag[]>([]);
  const [relatedArticles, setRelatedArticles] = useState<NewsArticle[]>([]);

  useEffect(() => {
    const fetchRelatedData = async () => {
      if (news?.tag_ids) {
        try {
          // Fetch tags
          const tagsData = await fetchTagsByIds(news.tag_ids);
          setTags(tagsData);

          // Fetch related articles based on the tags
          const relatedArticlesData = await fetchRelatedArticlesByTags(news.tag_ids);
          setRelatedArticles(relatedArticlesData);
        } catch (error) {
          console.error('Error fetching related data: ', error);
        }
      }
    };

    if (news) {
      fetchRelatedData();
    }
  }, [news]);

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50">
        <main className="max-w-4xl mx-auto px-4 py-8">
          <div className="animate-pulse space-y-8">
            <div className="space-y-4">
              <div className="h-4 bg-gray-200 rounded w-1/4"></div>
              <div className="h-8 bg-gray-200 rounded w-3/4"></div>
              <div className="h-4 bg-gray-200 rounded w-1/3"></div>
            </div>
            <div className="h-96 bg-gray-200 rounded-lg"></div>
            <div className="space-y-4">
              {[1, 2, 3].map((n) => (
                <div key={n} className="h-4 bg-gray-200 rounded"></div>
              ))}
            </div>
          </div>
        </main>
      </div>
    );
  }

  if (error || !news) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="text-center space-y-4">
          <h1 className="text-2xl font-bold text-gray-900">
            {error || 'செய்தி கிடைக்கவில்லை'}
          </h1>
          <button
            onClick={() => navigate('/')}
            className="text-red-600 hover:text-red-700"
          >
            முகப்பு பக்கத்திற்கு திரும்பவும்
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 w-full">
      <main className=" mx-auto py-8">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          <div className="lg:col-span-2 space-y-12 p-5">
            {/* News Header */}
            <NewsHeader news={news} />

            {/* News Content */}
            <NewsContent news={news} />

            {/* Related News */}
            <RelatedNews news={relatedArticles} tags={tags} currentArticle={news} />
          </div>

          {/* Sidebar (Advertisements and Featured Cards) */}
          <div className="w-full h-full">
            <div className="bg-white rounded-md shadow-md mb-4 w-full">
              <h3 className="text-xl font-semibold p-4">Featured Cards</h3>
              <ScrollableImages />
            </div>

            <div className="bg-white p-6 rounded-md shadow-md">
           
              <AdvertisementSection/>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default NewsDetailPage;
