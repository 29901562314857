import React from 'react';

interface ImageCardProps {
  imageUrl: string;
  title: string;
}

const ImageCard: React.FC<ImageCardProps> = ({ imageUrl, title }) => {
  return (
    <div className="flex-shrink-0 w-[280px] md:w-full bg-gray-100 p-4 rounded-md shadow-sm">
      <img
        src={imageUrl}
        alt={title}
        className="w-full h-48 md:h-auto object-cover rounded-md"
        loading="lazy"
      />
    </div>
  );
};

export default ImageCard;