import React from 'react';

interface ConfettiProps {
  color: string;
  delay: number;
}

const Confetti: React.FC<ConfettiProps> = ({ color, delay }) => {
  const style = {
    animation: `confetti 1s ${delay}s ease-out forwards`,
    backgroundColor: color,
  };

  return <div className="absolute w-2 h-2 rounded-sm" style={style} />;
};

export default Confetti;