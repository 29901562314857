import { collection, query, where, getDocs, orderBy } from 'firebase/firestore';
import { db } from '../firebase'; // Import your Firestore config
import { NewsArticle } from '../types/news'; // Adjust the path as necessary


export const fetchArticlesByCategory = async (categoryId?: string): Promise<NewsArticle[]> => {
  try {
    let q;

    if (categoryId != 'null') {
      // Query for articles with a specific category ID
      console.log('id found')
      q = query(
        collection(db, 'articles'),
        where('category.id', '==', categoryId)
      );
    } else {
      // Query for all articles (no filter)
      console.log('id not found')
      q = query(collection(db, 'articles'),orderBy('created_at', 'desc'));
    }

    const querySnapshot = await getDocs(q);

    const articles = querySnapshot.docs.map((doc) => {
      const data = doc.data();
      return {
        id: doc.id,
        tag_ids: data.tag_ids || [],
        title: data.title || '',
        description: data.description || '',
        summary: data.summary || '',
        image_url: data.image_url || '',
        category: data.category || { id: '', name: '' },
        created_at: data.created_at || new Date(),
        author: data.author || { id: '', name: '', image_url: '' },
      };
    });

    return articles;
  } catch (error) {
    console.error('Error fetching articles:', error);
    return [];
  }
};
