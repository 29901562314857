import React from 'react';

interface FireworkProps {
  color: string;
  delay: number;
  position: { x: number; y: number };
}

const Firework: React.FC<FireworkProps> = ({ color, delay, position }) => {
  return (
    <div
      className="absolute w-4 h-4"
      style={{
        left: `${position.x}%`,
        top: `${position.y}%`,
        animation: `firework 1s ${delay}s ease-out forwards`
      }}
    >
      {[...Array(12)].map((_, i) => (
        <div
          key={i}
          className="absolute w-0.5 h-8 origin-bottom"
          style={{
            backgroundColor: color,
            transform: `rotate(${i * 30}deg)`,
            animation: `spark 1s ${delay}s ease-out forwards`
          }}
        />
      ))}
    </div>
  );
};

export default Firework;