import React, { useEffect, useState } from 'react';
import { db } from '../firebase';
import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import ShimmerPlaceholder from '../components/ShimmerEffect';
import ImageCard from '../components/cards/imagecard';

interface Article {
  id: string;
  title: string;
  imageUrl: string;
  createdAt: any;
}

const AllCards: React.FC = () => {
  const [articles, setArticles] = useState<Article[]>([]);
  const [displayedArticles, setDisplayedArticles] = useState<Article[]>([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const pageSize = 9; // Show 9 images per page (3 rows x 3 images)

  useEffect(() => {
    const fetchAllCards = async () => {
      try {
        const articlesRef = query(collection(db, 'cards'), orderBy('createdAt', 'desc'));
        const snapshot = await getDocs(articlesRef);
        const articlesData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        })) as Article[];
        setArticles(articlesData);
        setDisplayedArticles(articlesData.slice(0, pageSize));
      } catch (error) {
        console.error('Error fetching cards:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAllCards();
  }, []);

  const handleShowMore = () => {
    const nextPage = page + 1;
    const nextArticles = articles.slice(0, nextPage * pageSize);
    setDisplayedArticles(nextArticles);
    setPage(nextPage);
  };

  if (loading) {
    return (
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 p-4">
        {[...Array(6)].map((_, index) => (
          <ShimmerPlaceholder key={index} />
        ))}
      </div>
    );
  }

  return (
    <div className="p-4">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
        {displayedArticles.map(article => (
          <ImageCard
            key={article.id}
            imageUrl={article.imageUrl}
            title={article.title}
          />
        ))}
      </div>
      {displayedArticles.length < articles.length && (
        <div className="flex justify-center mt-4">
          <button
            onClick={handleShowMore}
            className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
          >
            Show More
          </button>
        </div>
      )}
    </div>
  );
};

export default AllCards;
