// ShimmerPlaceholder.tsx
import React from 'react';
import ContentLoader from 'react-content-loader';

const ShimmerPlaceholder: React.FC = () => {
  return (
    <ContentLoader
      speed={2}
      width={400}
      height={160}
      viewBox="0 0 400 160"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="10" y="10" rx="5" ry="5" width="300" height="10" />
      <rect x="10" y="30" rx="5" ry="5" width="250" height="10" />
      <rect x="10" y="50" rx="5" ry="5" width="200" height="10" />
      <rect x="10" y="70" rx="5" ry="5" width="350" height="10" />
    </ContentLoader>
  );
};

export default ShimmerPlaceholder;
