import React from 'react';
import { useNews } from '../hooks/useNews';

const MarqueeNews: React.FC = () => {
  const { news, loading } = useNews();

  

  return (
    <div className="bg-red-700 text-white py-2 overflow-hidden z-1">
      <div
        className="whitespace-nowrap"
        style={{
          display: 'inline-block',
          animation: 'marquee 80s linear infinite',
        }}
      >
        {news.map((article) => (
          <span key={article.id} className="mx-4">
            • {article.title}
          </span>
        ))}
      </div>
      <style>
        {`
          @keyframes marquee {
            0% { transform: translateX(100%); }
            100% { transform: translateX(-100%); }
          }
        `}
      </style>
    </div>
  );
};

export default MarqueeNews;
